import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { setCustomLabel } from "@/store/MainTabs/actions";

const StudentModule = namespace("student");
const MainTabsModule = namespace("main-tabs");
const ProductModule = namespace("product");

@Component
export default class StudentStatusMixin extends Vue {
  @StudentModule.Getter("getSuccess")
  public success: any;

  @StudentModule.Getter("getError")
  public error: any;

  @StudentModule.Getter("getId")
  public studentId: any;

  @MainTabsModule.Action("removeTabById")
  public removeTabByIdAction: any;

  @MainTabsModule.Action("setCustomLabel")
  public setCustomLabelAction!: (customLabel: string | null) => void;

  @ProductModule.Getter("getSuccess")
  public productSuccess!: boolean;

  @StudentModule.Getter("getAvatarSuccess")
  public avatarSuccess!: boolean;
}
