import i18n from "@/i18n";

export const NONE = 1;
export const COMPANY = 2;
export const BILLING_ADDRESS = 3;

export const COST_BEARER_TYPES = [
  { id: NONE, name: i18n.t("students.none") },
  { id: COMPANY, name: i18n.t("students.company") },
  { id: BILLING_ADDRESS, name: i18n.t("students.bill_address") },
];
