import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ulrStringify } from "@/utils/UrlUtils";
import { ILanguage } from "@/interfaces/ILanguage";

const URL = "languages";

@Component
export default class LanguageRequestMixin extends Vue {
  public languages: Array<ILanguage> = [];
  public languagesLoading = false;
  public languagesSuccess = false;
  public languagesError: Record<any, any> | null = null;

  public async findAllLanguage(params: any = {}): Promise<void> {
    this.languagesLoading = true;
    this.languagesSuccess = false;
    this.languagesError = null;

    const args = ulrStringify(params);

    return await axios
      .get(`${URL}${args}`)
      .then((response: AxiosResponse) => {
        this.languages = response.data;
        this.languagesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.languagesError = error.response?.data;
      })
      .finally(() => {
        this.languagesLoading = false;
      });
  }
}
