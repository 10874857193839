






import { Component, Vue } from "vue-property-decorator";

@Component
export default class FscCardHeader extends Vue {
  public name = "FscCardHeader";
}
