import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ulrStringify } from "@/utils/UrlUtils";
import { IPriceList } from "@/interfaces/IPriceList";

const URL = "price-lists";

@Component
export default class PriceListRequestMixin extends Vue {
  public priceList: Array<IPriceList> = [];
  public priceListLoading = false;
  public priceListSuccess = false;
  public priceListError: Record<any, any> | null = null;

  public async findAllPriceList(params: any = {}): Promise<void> {
    this.priceListLoading = true;
    this.priceListSuccess = false;
    this.priceListError = null;

    const args = ulrStringify(params);

    return await axios
      .get(`${URL}${args}`)
      .then((response: AxiosResponse) => {
        this.priceList = response.data;
        this.priceListSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.priceListError = error.response?.data;
      })
      .finally(() => {
        this.priceListLoading = false;
      });
  }
}
