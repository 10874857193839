import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ulrStringify } from "@/utils/UrlUtils";
import { ICountry } from "@/interfaces/ICountry";

const URL = "countries";

@Component
export default class CountryRequestMixin extends Vue {
  public countries: Array<ICountry> = [];
  public countriesLoading = false;
  public countriesSuccess = false;
  public countriesError: Record<any, any> | null = null;

  public async findAllCountry(params: any = {}): Promise<void> {
    this.countriesLoading = true;
    this.countriesSuccess = false;
    this.countriesError = null;

    const args = ulrStringify(params);

    return await axios
      .get(`${URL}${args}`)
      .then((response: AxiosResponse) => {
        this.countries = response.data;
        this.countriesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.countriesError = error.response?.data;
      })
      .finally(() => {
        this.countriesLoading = false;
      });
  }
}
