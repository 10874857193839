import { Component, Vue } from "vue-property-decorator";
import { BILLING_ADDRESS, COMPANY } from "@/constants/CostBearerTypeId";
import { ASCENSION, CERTIFICATE, ELIMINATION_OF_AUTOMATIC_RESTRICTION, EXTENSION } from "@/constants/GratingTypes";
import { namespace } from "vuex-class";

const StudentModule = namespace("student");

@Component
export default class StudentMixin extends Vue {
  @StudentModule.Action("uploadAvatar")
  public uploadAvatar!: (formData: FormData) => Promise<void>;

  @StudentModule.Getter("getAvatarIsLoading")
  public avatarIsLoading!: boolean;

  public onAbort(): void {
    this.$router.back();
  }

  public isCostBearerIdBillingAddress(costBearerType: { id: number; name: string }): boolean {
    return costBearerType?.id === BILLING_ADDRESS;
  }

  public isCostBearerIdCompany(costBearerType: { id: number; name: string }): boolean {
    return costBearerType?.id === COMPANY;
  }

  public isGrantingTypeWithExistingLicenseClass(type: { id: number; name: string }, licenseClass: any): boolean {
    return (
      type?.id === EXTENSION ||
      type?.id === ASCENSION ||
      type?.id === ELIMINATION_OF_AUTOMATIC_RESTRICTION ||
      (type?.id === CERTIFICATE && licenseClass?.keyNumber === 196)
    );
  }

  public educationObject(education: any): any {
    const isGrantingTypeWithExistingLicenseClass = this.isGrantingTypeWithExistingLicenseClass(education.grantingType, education.licenseClassWithKeyNumber);
    return {
      id: education?.id,
      studentId: education.studentId,
      mainLicenseClass: education.mainLicenseClass,
      licenseClassWithKeyNumber: education.licenseClassWithKeyNumber,
      bf17: education.bf17,
      additionalClass: education.additionalClass,
      fillingDate: education.fillingDate,
      grantingType: education.grantingType,
      firstExistingLicenseClass: isGrantingTypeWithExistingLicenseClass ? education.firstExistingLicenseClass : null,
      secondExistingLicenseClass: isGrantingTypeWithExistingLicenseClass ? education.secondExistingLicenseClass : null,
      firstExistingDrivingLicenseNumber: isGrantingTypeWithExistingLicenseClass ? education.firstExistingDrivingLicenseNumber : null,
      secondExistingDrivingLicenseNumber: isGrantingTypeWithExistingLicenseClass ? education.secondExistingDrivingLicenseNumber : null,
      firstExistingLicenseClassIssueDate: isGrantingTypeWithExistingLicenseClass ? education.firstExistingLicenseClassIssueDate : null,
      secondExistingLicenseClassIssueDate: isGrantingTypeWithExistingLicenseClass ? education.secondExistingLicenseClassIssueDate : null,
      costBearer: education.costBearer ? this.costBearerObject(education.costBearer) : null,
      costBearerTypeId: education.costBearerTypeId?.id || null,
      costBearerEmail: education.costBearerEmail,
      theoryCourse: education.theoryCourse,
      secondTheoryCourse: education.secondTheoryCourse ? education.secondTheoryCourse : null,
      thirdTheoryCourse: education.thirdTheoryCourse ? education.thirdTheoryCourse : null,
      instructors: education.instructors,
      initialPriceList: education.initialPriceList,
      paymentWorkflow: education.paymentWorkflow,
      combined: education.combined,
      practicalEducationFinishDate: education.practicalEducationFinishDate,
      theoreticalEducationFinishDate: education.theoreticalEducationFinishDate,
      processNumber: education.processNumber,
      zeroVat: education.zeroVat,
      bkfDrivingLicenseNumber: education.bkfDrivingLicenseNumber,
      bkfDrivingLicenseNumberExpirationDate: education.bkfDrivingLicenseNumberExpirationDate,
      bkfFQNNumber: education.bkfFQNNumber,
      bkfFQNNumberExpirationDate: education.bkfFQNNumberExpirationDate,
      bkfDriverCardExpirationDate: education.bkfDriverCardExpirationDate,
      bkfADRExpirationDate: education.bkfADRExpirationDate,
      bkfDriverLicenseClasses: education.bkfDriverLicenseClasses
    };
  }

  public studentObject(student: any): any {
    return {
      id: student?.id,
      email: student.email,
      learnStep: student.learnStep,
      location: student.location,
      privatePhone: student.privatePhone,
      companyPhone: student.companyPhone,
      nationality: student.nationality,
      applicationCompletedDate: student.applicationCompletedDate,
      visualAid: student.visualAid,
      firstName: student.firstName,
      lastName: student.lastName,
      branch: student.branch,
      birthDate: student.birthDate,
      birthPlace: student.birthPlace,
      postalCode: student.postalCode,
      streetAndNumber: student.streetAndNumber,
      mobilePhone: student.mobilePhone,
      title: student.title,
      furtherEducationStudent: student.furtherEducationStudent,
      learnAppEmail: student.learnAppEmail,
      language: student.language,
      returnNumber: student.returnNumber,
      returnDate: student.returnDate,
      checkpoint: student.checkpoint,
      info: student.info,
      additionalInfo: student.additionalInfo,
      tuvService: student.tuvService,
      testingOrganization: student.testingOrganization,
    };
  }

  public billingAddressObject(billingAddress: any): any {
    return {
      id: billingAddress?.id,
      studentEducationId: billingAddress.studentEducationId,
      title: billingAddress.billingAddressTitle,
      firstName: billingAddress.billingAddressFirstName,
      lastName: billingAddress.billingAddressLastName,
      postalCode: billingAddress.billingAddressPostalCode,
      location: billingAddress.billingAddressLocation,
      streetAndNumber: billingAddress.billingAddressStreetAndNumber,
    };
  }

  public costBearerObject(costBearer: any): any {
    return {
      id: costBearer.id,
      name: costBearer.name,
    };
  }

  public get hasNotPermissionWrite(): boolean {
    return !this.$can(this.MODULE_CUSTOMERS_WRITE);
  }
}
