














































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import StudentMixin from "@/mixins/StudentMixin";
import FscCard from "@/components/Card/FscCard.vue";
import FscCardHeader from "@/components/Card/FscCardHeader.vue";
import Validation from "@/components/Validation.vue";
import { ILanguage } from "@/interfaces/ILanguage";

@Component({
  components: { Validation, FscCardHeader, FscCard },
})
export default class DriveBuzzTab extends mixins(StudentMixin) {
  public name = "DriveBuzzTab";

  @Prop({ default: () => [] })
  public languages!: Array<ILanguage>;

  @Prop()
  public studentData!: any;

  @Prop()
  public v!: any;

  public get languageOption(): Array<any> {
    return this.languages;
  }
}
