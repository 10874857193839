import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import CrudService from "@/services/CrudService";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";

const crudService = new CrudService();

@Component
export default class GrantingTypeRequestMixin extends Vue {
  public grantingTypesOptions: Array<IBasicNamedDTO> = [];
  public grantingTypesLoading = false;
  public grantingTypesSuccess = false;
  public grantingTypesError = null;

  public async fetchGrantingTypesByLicenseClass(licenseClass: string) {
    this.grantingTypesLoading = true;
    this.grantingTypesSuccess = false;
    this.grantingTypesError = null;
    return await crudService
      .findAll({
        resource: `/granting-types/by-license-class?licenseClass=${licenseClass}`,
        descriptionField: "",
      })
      .then((response: AxiosResponse) => {
        this.grantingTypesOptions = response.data;
        this.grantingTypesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.grantingTypesError = error.response?.data;
      })
      .finally(() => {
        this.grantingTypesLoading = false;
      });
  }
}
