import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IBranch } from "@/interfaces/IBranch";
import { ulrStringify } from "@/utils/UrlUtils";
import createCancelableRequest from "@/utils/axiosCancelable";

const URL = "license-classes";

@Component
export default class LicenseClassRequestMixin extends Vue {
  protected licenseClassCancelableRequest = createCancelableRequest(LicenseClassRequestMixin.name);

  public licenseClasses: Array<IBranch> = [];
  public licenseClassesLoading = false;
  public licenseClassesSuccess = false;
  public licenseClassesError: Record<any, any> | null = null;

  public async findAllBaseLicenseClass(params: any = {}): Promise<void> {
    this.licenseClassesLoading = true;
    this.licenseClassesSuccess = false;
    this.licenseClassesError = null;

    const args = ulrStringify(params);

    return await axios
      .get(`${URL}/base-license-classes${args}`)
      .then((response: AxiosResponse) => {
        this.licenseClasses = response.data;
        this.licenseClassesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.licenseClassesError = error.response?.data;
      })
      .finally(() => {
        this.licenseClassesLoading = false;
      });
  }

  protected relatedDocuments = [];
  private relatedDocumentsLoadingQueue = 0;
  protected relatedDocumentsSuccess = false;
  protected relatedDocumentsError = null;

  public get relatedDocumentsLoading() {
    return this.relatedDocumentsLoadingQueue > 0;
  }

  public async getRelatedDocuments(licenseClass: string): Promise<void> {
    this.relatedDocumentsLoadingQueue++;
    this.relatedDocumentsSuccess = false;
    this.relatedDocumentsError = null;

    return await this.licenseClassCancelableRequest
      .makeRequest({ url: `${URL}/related-documents?licenseClass=${licenseClass}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.relatedDocuments = response.data;
        this.relatedDocumentsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.relatedDocumentsError = error.response?.data;
      })
      .finally(() => {
        this.relatedDocumentsLoadingQueue--;
      });
  }

  public destroyed() {
    this.licenseClassCancelableRequest.cancelPreviousRequest();
  }
}
