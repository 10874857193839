import { MAX_FILE_SIZE_NORMAL_UPLOAD, MAX_FILE_SIZE_CONFIGURATION_UPLOAD, MAX_FILE_SIZE_MULTI_PAGES_UPLOAD } from "@/constants/Files";

export function checkFileSizeBeforeUpload(file: File | any, option?: any): boolean {
  if (!file) return true;
  let fileSize: any = 0;
  if (isBase64Data(file)) {
    fileSize = getBase64Size(file);
  } else {
    fileSize = file.size;
  }
  if (!option) {
    return fileSize <= MAX_FILE_SIZE_NORMAL_UPLOAD;
  }
  switch (option) {
    case "normal":
      return fileSize <= MAX_FILE_SIZE_NORMAL_UPLOAD;
    case "configuration":
      return fileSize <= MAX_FILE_SIZE_CONFIGURATION_UPLOAD;
    case "multipages":
      return fileSize <= MAX_FILE_SIZE_MULTI_PAGES_UPLOAD;
  }
  return true;
}

export function isBase64Data(data: any) {
  if (data && typeof data === "string" && data.split(/[;,]/)[1].toLowerCase() === "base64") return true;
  return false;
}

export function getBase64Size(file: any) {
  if (!file || (typeof file !== "string" && !isBase64Data(file))) return;
  const dataBase64 = file.substring(file.indexOf(",") + 1);
  const decodedData = atob(dataBase64);
  return decodedData.length || 0;
}
