import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";
import { ICostBearerStudentListFilter } from "@/interfaces/ICostBearerStudentListFilter";
import axios from "@/utils/axios";
import createCancelableRequest from "@/utils/axiosCancelable";

const crudService = new CrudService();
const URL = "/payment-workflows";

@Component
export default class PaymentWorkflowRequestMixin extends Vue {
  protected costBearerStudentList = [];
  protected costBearerStudentListSuccess = false;
  protected costBearerStudentListError: Record<any, any> | null = null;
  protected costBearerStudentListLoading = false;

  protected costBearerCsvExportSuccess = false;
  protected costBearerCsvExportError: Record<any, any> | null = null;
  protected costBearerCsvExportLoading = false;

  public async fetchCostBearerStudentList(costBearerStudentListFilter: ICostBearerStudentListFilter): Promise<void> {
    this.costBearerStudentListSuccess = false;
    this.costBearerStudentListError = null;
    this.costBearerStudentListLoading = true;
    return await crudService
      .filter({
        resource: `${URL}/cost-bearer/${costBearerStudentListFilter.costBearerId}/students-list`,
        filter: costBearerStudentListFilter,
      })
      .then((response: AxiosResponse) => {
        this.costBearerStudentList = response.data;
        this.costBearerStudentListSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.costBearerStudentListError = error.response?.data;
      })
      .finally(() => {
        this.costBearerStudentListLoading = false;
      });
  }

  public async generateCsvExport(costBearerStudentListFilter: ICostBearerStudentListFilter): Promise<void> {
    this.costBearerCsvExportSuccess = false;
    this.costBearerCsvExportError = null;
    this.costBearerCsvExportLoading = true;
    return await axios
      .post(`${URL}/cost-bearer/${costBearerStudentListFilter.costBearerId}/students-list/filter/csv`, costBearerStudentListFilter, {
        responseType: "blob",
      })
      .then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.costBearerCsvExportSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.costBearerCsvExportError = error.response?.data;
      })
      .finally(() => {
        this.costBearerCsvExportLoading = false;
      });
  }

  private findAllPaymentWorkflowsRequest = createCancelableRequest(PaymentWorkflowRequestMixin.name);

  protected paymentWorkflows = [];
  protected paymentWorkflowsSuccess = false;
  protected paymentWorkflowsError: Record<any, any> | null = null;
  private paymentWorkflowsLoadingQueue = 0;

  public get paymentWorkflowsLoading() {
    return this.paymentWorkflowsLoadingQueue > 0;
  }

  public async findAllPaymentWorkflows(): Promise<void> {
    this.paymentWorkflowsLoadingQueue++;
    this.paymentWorkflowsSuccess = false;
    this.paymentWorkflowsError = null;
    return await this.findAllPaymentWorkflowsRequest
      .makeRequest({ url: `/payment-workflows`, method: "get" })
      .then((response: AxiosResponse) => {
        this.paymentWorkflows = response.data;
        this.paymentWorkflowsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.paymentWorkflowsError = error.response?.data;
      })
      .finally(() => {
        this.paymentWorkflowsLoadingQueue--;
      });
  }
}
