import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ILivePayMandate } from "@/interfaces/ILivePayMandate";

const URL = "live-pay-mandate";

@Component
export default class LivePayMandateRequestMixin extends Vue {
  public livePayMandatesByStudentEducation: Array<ILivePayMandate> = [];
  public livePayMandatesByStudentEducationLoading = false;
  public livePayMandatesByStudentEducationSuccess = false;
  public livePayMandatesByStudentEducationError: Record<any, any> | null = null;

  public livePayMandateRevoke: ILivePayMandate | null = null;
  public livePayMandateRevokeLoading = false;
  public livePayMandateRevokeSuccess = false;
  public livePayMandateRevokeError: Record<any, any> | null = null;

  public livePayMandatesByStudent: Array<ILivePayMandate> = [];
  public livePayMandatesByStudentLoading = false;
  public livePayMandatesByStudentSuccess = false;
  public livePayMandatesByStudentError: Record<any, any> | null = null;

  public async findLivePayMandatesByStudentEducation(studentEducationId: number): Promise<void> {
    this.livePayMandatesByStudentEducationLoading = true;
    this.livePayMandatesByStudentEducationSuccess = false;
    this.livePayMandatesByStudentEducationError = null;

    return await axios
      .get(`${URL}/by-student-education/${studentEducationId}`)
      .then((response: AxiosResponse) => {
        this.livePayMandatesByStudentEducation = response.data;
        this.livePayMandatesByStudentEducationSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.livePayMandatesByStudentEducationError = error.response?.data;
      })
      .finally(() => {
        this.livePayMandatesByStudentEducationLoading = false;
      });
  }

  public async mandateRevoke(mandateId: number): Promise<void> {
    this.livePayMandateRevokeLoading = true;
    this.livePayMandateRevokeSuccess = false;
    this.livePayMandateRevokeError = null;
    return await axios
      .get(`${URL}/revoke-mandate/${mandateId}`)
      .then((response: AxiosResponse) => {
        this.livePayMandateRevoke = response.data;
        this.livePayMandateRevokeSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.livePayMandateRevokeError = error.response?.data;
      })
      .finally(() => {
        this.livePayMandateRevokeLoading = false;
      });
  }

  public async findLivePayMandatesByStudent(studentId: number): Promise<void> {
    this.livePayMandatesByStudentLoading = true;
    this.livePayMandatesByStudentSuccess = false;
    this.livePayMandatesByStudentError = null;

    return await axios
      .get(`${URL}/by-student/${studentId}`)
      .then((response: AxiosResponse) => {
        this.livePayMandatesByStudent = response.data;
        this.livePayMandatesByStudentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.livePayMandatesByStudentError = error.response?.data;
      })
      .finally(() => {
        this.livePayMandatesByStudentLoading = false;
      });
  }

  public createLivePayMandateGoCardlessLink = "";
  public createLivePayMandateGoCardlessSuccess = false;
  public createLivePayMandateGoCardlessLoading = false;
  public createLivePayMandateGoCardlessError = null;

  public async createLivePayMandateGoCardless(id: number): Promise<void> {
    this.createLivePayMandateGoCardlessLoading = true;
    this.createLivePayMandateGoCardlessSuccess = false;
    this.createLivePayMandateGoCardlessError = null;

    return await axios
      .post(`live-pay-mandate/generate-go-cardless-link/${id}`)
      .then((response: AxiosResponse) => {
        this.createLivePayMandateGoCardlessLink = response.data;
        this.createLivePayMandateGoCardlessSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.createLivePayMandateGoCardlessError = error.response?.data;
      })
      .finally(() => {
        this.createLivePayMandateGoCardlessLoading = false;
      });
  }

  public sendLivePayMandateEmailSuccess = false;
  public sendLivePayMandateEmailLoading = false;
  public sendLivePayMandateEmailError = null;

  public async sendLivePayMandateEmail(email: string, educationId: number): Promise<void> {
    this.sendLivePayMandateEmailLoading = true;
    this.sendLivePayMandateEmailSuccess = false;
    this.sendLivePayMandateEmailError = null;

    return await axios
      .post(`/students/send-live-pay-mandate-dispatch-email?email=${email}&educationId=${educationId}`)
      .then((response: AxiosResponse) => {
        this.sendLivePayMandateEmailSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.sendLivePayMandateEmailError = error.response?.data;
      })
      .finally(() => {
        this.sendLivePayMandateEmailLoading = false;
      });
  }

  public cancelLivePayMandateSuccess = false;
  public cancelLivePayMandateLoading = false;
  public cancelLivePayMandateError = null;

  public async cancelLivePayMandate(mandateId: number): Promise<void> {
    this.cancelLivePayMandateLoading = true;
    this.cancelLivePayMandateSuccess = false;
    this.cancelLivePayMandateError = null;

    return await axios
      .put(`/live-pay-mandate/revoke-mandate/${mandateId}`)
      .then((response: AxiosResponse) => {
        this.cancelLivePayMandateSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.cancelLivePayMandateError = error.response?.data;
      })
      .finally(() => {
        this.cancelLivePayMandateLoading = false;
      });
  }
}
