








import { Vue, Component, Prop } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import { namespace } from "vuex-class";

const StudentModule = namespace("student");

@Component({
  components: { FscModal },
})
export default class SendPasswordModal extends Vue {
  public name = "SendPasswordModal";

  @Prop({ type: String, default: () => "send-password-modal-id" })
  public modalId!: string;

  @Prop({ type: String, required: false })
  public studentEmail!: string;

  @StudentModule.Action("sendPasswordChange")
  public sendPasswordChange!: (studentEmail: string) => Promise<void>;

  protected ok(): void {
    if (!this.studentEmail) return;
    this.sendPasswordChange(this.studentEmail);
  }
}
